import React, { useRef} from 'react';
import { Link } from "gatsby";
import Nav from 'react-bootstrap/Nav'
import Tab from 'react-bootstrap/Tab'
import Layout from "../components/layout"
import SEO from "../components/seo"
import BloqueContacto from "../components/bloque-contacto"

import { plain_planificacion, icon_laptop, icon_auction, icon_reduce, icon_file, icon_idea } from '../images/featured';
import { icon_plain, icon_plain_medium, funcionalidades_1, funcionalidades_2, funcionalidades_3 } from '../images/plain';
import { icon_forecast, icon_integracion, icon_optimizer, icon_spexpert, icon_spexpert_medium } from '../images/sp-expert';


const GestionTiempoPage = () => {

    let toPlain = useRef(),
        toSp = useRef();


	return (
		<Layout className="page-gestion-tiempo">
			<SEO 
                title="Gestión de tiempo - Planificación de personal, Workforce Management, Plain, SP-EXPERT, Aldextra" 
                description="Software de Planificación de personal con algoritmos de optimización de la gestión de turnos. SP-Expert es el Software de Workforce management y Plain, la solución de gestión del tiempo para Pymes"
            />

            <section className="bloque bloque-featured">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6 main-header">
                            <h1>Expertos en soluciones de Gestión del tiempo</h1>
                            <h2 id="sub">Control horario, gestión de vacaciones, planificación de turnos y optimización</h2>
                            <Link className="btn btn-accent" title="Contacta con DMS" to="/contacto">Contactar</Link>
                        </div>

                        <div className="col-xl-6">
                            <img src={plain_planificacion} alt="Soporte informático que te dejará tranquilo" />
                        </div>
                    </div>
                </div>

                <div className="super-powers">
                    <div className="container">
                        <div className="row justify-content-center">
                            <article className="col">
                                <span className="icono-bola icono-power">
                                    <img src={icon_file} alt="excel" />
                                </span>
                                <h3>No más excels</h3>
                            </article>
                            <article className="col">
                                <span className="icono-bola icono-power">
                                    <img src={icon_idea} alt="idea" />
                                </span>
                                <h3>Automatiza la planificación</h3>
                            </article>
                            <article className="col">
                                <span className="icono-bola icono-power">
                                    <img src={icon_reduce} alt="reduce" />
                                </span>
                                <h3>Reduce absentismo</h3>
                            </article>
                            <article className="col">
                                <span className="icono-bola icono-power">
                                    <img src={icon_auction} alt="auction" />
                                </span>
                                <h3>Cumple con la Ley de control horario</h3>
                            </article>																		
                            <article className="col">
                                <span className="icono-bola icono-power">
                                    <img src={icon_laptop} alt="laptop" />
                                </span>
                                <h3>Digitaliza procesos manuales</h3>
                            </article>

                        </div>
                    </div>
                </div>      
             </section>

            <section className="bloque-servicios">
                <div className="container">
                    <div className="servicios">

                        <article className="servicio">
                            <div className="servicio-container">
                                <span className="icono-bola">
                                    <img src={icon_plain} alt="plain" />
                                </span>
                                <h3>Plain, la solución de Gestión del tiempo para Pymes</h3>
                                <p>Solución en la nube para Pymes que facilita la digitalización de tus procesos de gestión del tiempo: gestión y aprobación de vacaciones y ausencias, registro de la jornada para el cumplimiento de la nueva Ley del Control Horario y planificación de turnos.</p>
                            </div>
                            <div className="btn-container">
                                <button type="button" className="btn btn-accent" onClick={()=>window.scrollTo({ behavior: 'smooth', top: toPlain.current.offsetTop-100 }) }>Más información</button>
                            </div>
                        </article>																	


                        <article className="servicio">
                            <div className="servicio-container">
                                <span className="icono-bola">
                                    <img src={icon_spexpert} alt="sp-expert" />
                                </span>
                                <h3>SP-Expert, la solución de Gestión del tiempo Grandes corporaciones</h3>
                                <p>Solución de Workforce Management para medianas y grandes empresas con planificiación automática de turnos, portal del empleado y predición de la demanda de personal. SP-Expert tiene tiene una gran implantación en sectores como salud, retail, hostelería, aeropuertos e industrial.</p>
                            </div>
                            <div className="btn-container">
                                <button type="button" className="btn btn-accent" onClick={()=>window.scrollTo({ behavior: 'smooth', top: toSp.current.offsetTop-70 })}>Más información</button>
                            </div>
                        </article>																	

                    </div>			
                </div>
            </section>				

            <section className="bloque-sobre-plain" ref={toPlain}>
                <div className="container">
                    <span className="icono-bola">
                        <img src={icon_plain_medium} alt="plain" />
                    </span>
                    <h2 className="title">Sobre plain</h2>
                    <p>Plain es una aplicación de gestión del tiempo desarrollada por  DMS que tiene como ojetivo simplificar la gestión del tiempo de las Pymes. Con Plain los clientes pueden gestionar de forma unificada el control horario, la gestión de vacaciones y la planificación de turnos.</p>
                </div>
            </section>

            <section className="bloque-plain">
                <div className="container">
                    <div className="bloque-header">
                        <h2 className="title">Plain, la solución de Gestión del tiempo para pymes</h2>
                    </div>

                    <Tab.Container defaultActiveKey="tab1">
                        <div className="row row-tabs">
                            <div className="col-md-6">
                                <Nav className="nav nav-tabs">
                                    <Nav.Item className="active">
                                         <Nav.Link eventKey="tab1">
                                            <div className="item-content">
                                                <h4>Control horario</h4>
                                                <p>Tu equipo podrá hacer marcajes de entrada y salida geolocalizados desde un móvil, una tableta o un ordenador</p>
                                            </div>
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="tab2">
                                            <div className="item-content">
                                                <h4>Gestión de vacaciones y ausencias</h4>
                                                <p>Plain permite gestionar fácilmente flujos de aprobación de vacaciones y ausencias entre supervisores y empleados</p>
                                            </div>
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="tab3">
                                            <div className="item-content">
                                                <h4>Planificación de turnos automática</h4>
                                                <p>Plain utiliza algoritmos inteligentes para planificar automáticamente los turnos de trabajo</p>
                                            </div>
                                        </Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </div>
                            <Tab.Content className="col-md-6">
                                <Tab.Pane eventKey="tab1">
                                    <img src={funcionalidades_1} alt="Imagen Control horario" />
                                </Tab.Pane>
                                <Tab.Pane eventKey="tab2">
                                    <img src={funcionalidades_2} alt="Imagen Gestión de ausencias"/>
                                </Tab.Pane>
                                <Tab.Pane eventKey="tab3">
                                    <img src={funcionalidades_3} alt="Imagen Planificación de turnos"/>
                                </Tab.Pane>                                
                            </Tab.Content>    
                        </div>
                    </Tab.Container>



                    <section className="plain-mobile">

                        <article className="plain-item">
                            <img src={funcionalidades_1} alt="Imagen Control horario" />
                            <div className="item-content">
                                <h4>Control horario</h4>
                                <p>Tu equipo podrá hacer marcajes de entrada y salida geolocalizados desde un móvil, una tableta o un ordenador</p>
                            </div>
                        </article>

                        <article className="plain-item">
                            <img src={funcionalidades_2} alt="Imagen Gestión de ausencias"/>
                            <div className="item-content">
                                <h4>Gestión de vacaciones y ausencias</h4>
                                <p>Plain permite gestionar fácilmente flujos de aprobación de vacaciones y ausencias entre supervisores y empleados</p>
                            </div>
                        </article>

                        <article className="plain-item">
                            <img src={funcionalidades_3} alt="Imagen Planificación de turnos"/>
                            <div className="item-content">
                                <h4>Planificación de turnos automática</h4>
                                <p>Plain utiliza algoritmos inteligentes para planificar automáticamente los turnos de trabajo</p>
                            </div>
                        </article>

                    </section>


                    <div className="btn-container">
                        <a className="btn btn-accent" href="https://app.plain.ninja/register" rel="noopener noreferrer" target="_blank">Probar ahora</a>
                    </div>

                </div>
            </section>

            <section className="bloque-sobre-sp" ref={toSp}>
                <div className="container">
                    <span className="icono-bola">
                        <img src={icon_spexpert_medium} alt="spexpert" />
                    </span>
                    <h2 className="title">Sobre SP-EXPERT</h2>
                    <p>
                        SP-Expert es una solución profesional de Workforce Productivity que permite dimensionar el número idóneo de empleados con la cualificación necesaria a su debido tiempo en el lugar de trabajo adecuado y con el mejor ratio coste/beneficio. </p>
                    <p>
                        Con SP-Expert se aumenta la productividad en un 20% y se reducen los gastos de personal entre 20 y 70 euros por empleado y mes. Además, el tiempo dedicado al control y planificación de los planificadores se reduce hasta un 75%.
                    </p>
                </div>
            </section>


            <section className="bloque-sp">
                <div className="container">
                    <div className="bloque-header">
                        <h2 className="title">SP-Expert, la solución de Gestión del tiempo Grandes corporaciones</h2>
                    </div>

                    <article className="funciones-sp">
                        <div className="row">
                            <div className="funciones-img col-sm-6">
                                <img src={icon_forecast} alt="forecast" />
                            </div>
                            <div className="funciones-info col-sm-6">
                                <h3>Forecast</h3>
                                <p>Con el módulo Forecast de SP-Expert obtendrás previsiones un 50% más precisas que en sistemas similares gracias a algoritmos genéticos (GA). </p>
                            </div>
                        </div>
                    </article>

                    <article className="funciones-sp">
                        <div className="row">
                            <div className="funciones-img col-sm-6">
                                <img src={icon_optimizer} alt="optimizer" />
                            </div>
                            <div className="funciones-info col-sm-6">
                                <h3>Optimizer</h3>
                                <p>El módulo Optimizer permite hacer la planificación de personal automáticamente a partir de la previsión de la demanda. El algoritmo de planificación tiene en cuenta las preferencias, cambios de turno y las peticiones de vacaciones de los empleados y tambien las reglas de contrato. </p>
                            </div>
                        </div>
                    </article>

                    <article className="funciones-sp">
                        <div className="row">
                            <div className="funciones-img col-sm-6">
                                <img src={icon_integracion} alt="integracion" />
                            </div>
                            <div className="funciones-info col-sm-6">
                                <h3>Integración con nómina y ERP</h3>
                                <p>
                                    SP-Expert tiene interfaces estándar de exportación e importación de datos desde SAP, Meta4 o A3. 
                                    De esta manera, podemos importar empleados y exportar las  incidencias de nómina para el cálculo de ésta. Además, es posible importar y exportar todos los datos gracias al motor de flujos de datos de SP-Expert.
                                </p>
                            </div>
                        </div>
                    </article>

                    <div className="btn-container">
                        <Link className="btn btn-accent" to="/contacto">Contactar</Link>
                    </div>
                </div>
            </section>

            <BloqueContacto 
                extra='remove-before'
            />


		</Layout>
	);
};

export default GestionTiempoPage


//AiOutlineFileText
// no trobo totes les icones a react-icons
